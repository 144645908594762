import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head';
import layoutStyles from '../styles/style.module.scss'

const IndexPage = () => {
    return (
        <div>
            <Layout>
                <Head title= "Helvio Prevelato Gregório"/>
                <h1 className={layoutStyles.t1}>Helvio Prevelato Gregório</h1>
                <p className={layoutStyles.t2}>Oceanographer, PhD</p>
                <p className={layoutStyles.t2}><br></br></p>
                <p className={layoutStyles.parag_i}>helvio@hpgregorio.net</p>
                <p className={layoutStyles.parag_i}>SMS/WhatsApp: +55 11 995 693 070</p>
                <p className={layoutStyles.t2}><br></br></p>
                <p className={layoutStyles.parag_i}><a href="http://hpgregorio.net/arquivos/CV_HPGregorio_jan2020.pdf" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Curriculum Vitæ</a></p>
               
            </Layout>
        </div>
    )    
}

export default IndexPage
